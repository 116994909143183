#Map {
    --offset: #{rem(50)};
    --bg: red;
    --height: calc(var(--href) - var(--full-header-height));

    @media (min-width: $smartphone) {
        --href: 100vh;
    }

    @media (max-width: $smartphone) {
        --href: calc(var(--vh, 1vh) * 100);
    }
}

#Map {
    @include z-index($z-index-map);
    position: fixed;
    width: 100vw;
    height: var(--height);
    top: var(--full-header-height);
    left: 0;
    right: 0;
    background-color: var(--bg);
    overflow: hidden;
}

#MapboxHolder {
    position: absolute;
    top: calc(var(--offset) * -1);
    left: 0;
    width: 100%;
    height: calc(100% + var(--offset) * 2);
}

.marker-ambulance {
    background-size: cover;
    width: 120px;
    height: 120px;
    cursor: pointer;
}

@keyframes radar {
    0% {
        opacity: .4;
        transform: scale3d(1,1,1);
    }

    100% {
        opacity: 0;
        transform: scale3d(5,5,5);
    }
  }


.marker-me {
    background-size: cover;
    width: 20px;
    height: 20px;
    background-color: var(--black);
    border-radius: 50%;
    cursor: pointer;
    //animation: .6s infinite forwards slidein;
    //animation-timing-function: ease-in;

    &::after,
    &::before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        animation: 1s infinite forwards radar;
        animation-timing-function: ease-in;
        transform-origin: center;
        background-color: black;
    }

    &::after {
        animation-delay: .5s;
    }
}

.map__interface {
    position: absolute;
    @include z-index(2);
    display: flex;
    flex-direction: column;
    gap: #{rem(12)};

    @media (min-width: $smartphone) {
        right: #{rem(38)};
        bottom: #{rem(38)};
    }

    @media (max-width: $smartphone) {
        right: #{rem(20)};
        bottom: #{rem(25)};
    }

    button {
        @include basic-a();
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--white);
        border-radius: 50%;
        width: #{rem(44)};
        height: #{rem(44)};
        padding: 0;

        svg {
            fill: var(--black);
            width: 100%;
            height: 100%;
        }
    }
}
  