@use "sass:math";

.block-info {
    --color: var(--black);
    --font-size-cities: #{rem(17)};
    --font-size-title: #{rem(12)};
    --font-size: #{rem(10)};
    --padding: #{rem(21)};
}

.block-info {
    padding: 0 var(--padding) #{rem(24)};
    border-bottom: var(--stroke) solid var(--color);
}

.block-info__content {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: #{rem(20)};

    &:before {
        content: '';
        width: 100%;
        flex: 1 0 100%;
        height: var(--stroke);
        background-color: var(--color);
        margin-bottom: #{rem(11)};
    }
    
    > * {
        flex: 0 0 50%;
    }

    .cities {
        @include font-sans(1);
        font-size: var(--font-size-cities);

        li {
            display: flex;
            justify-content: space-between;
            padding-right: #{rem(15)};
        }
    }

    .image-holder {
        padding-left: #{rem(15)};
    }

    figure {
        @include aspect-ratio(312,232);

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        }
    }
}

.block-info__barcode {
    position: relative;
    display: flex;
    flex-wrap: wrap;

    &:before {
        content: '';
        width: 100%;
        flex: 1 0 100%;
        height: #{rem(12)};
        background-color: var(--color);
        margin-bottom: #{rem(12)};
    }

    &:after {
        position: absolute;
        content: '';
        right: 0;
        top: 0;
        width: var(--stroke);
        height: #{rem(40)};
        background-color: var(--color);
    }

    > * {
        flex: 0 0 50%;
    }

    .image-holder {
        padding-right: #{rem(5)};

        > figure {
            border: var(--stroke) dashed var(--color);
        }
    }

    .info {
        padding-top: #{rem(10)};
        padding-left: #{rem(17)};

        > .title {
            @include font-sans-bold(1);
            text-wrap: balance;
            font-size: var(--font-size-title);
            text-transform: uppercase;
        }

        > p {
            @include font-sans();
            text-wrap: balance;
            font-size: var(--font-size);
            margin: 1em 0 1.8em;
        }

        > a {
            @include basic-a();
            @include font-sans();
            font-size: var(--font-size);
            text-transform: uppercase;
            color: currentColor;
        }
    }

}

