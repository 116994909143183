@use "sass:math";

:root {
  --y-header: 0;

  --padding-xl: #{math.div(120px, 16px) * 1rem};
  --padding-l: #{math.div(90px, 16px) * 1rem};
  --padding-m: #{math.div(60px, 16px) * 1rem};
  --padding-s: #{math.div(30px, 16px) * 1rem};
  --padding-xs: #{math.div(15px, 16px) * 1rem};
  --padding-xxs: #{math.div(8px, 16px) * 1rem};
  --padding-xxxs: #{math.div(4px, 16px) * 1rem};

  
  
  --scrollbar-height: #{math.div(40px, 16px) * 1rem};
  --full-header-height: calc(var(--header-height) + var(--billboard-height));
}

@media (min-width: $smartphone) {
  :root {
    --header-height: #{rem(58)};
    --billboard-height: #{rem(286)};
    --stroke: #{rem(1)};
  }
}

@media (max-width: $smartphone) {
  :root {
    --header-height: #{rem(43)};
    --billboard-height: #{rem(170)};
    --stroke: 1px;
  }
}
