@use "sass:math";

.block-video {
    --padding: #{rem(24)} #{rem(21)};
}

.block-video {
    padding: var(--padding);
    border-bottom: var(--stroke) solid var(--black);
   
    .holder {
        width: 100%;
        height: auto;
        position: relative;
        border: var(--stroke) dashed currentColor;
        @include aspect-ratio(346,342);

        video, svg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        video {
            object-fit: cover;
        }

        svg {
            fill: var(--black);
        }
    }
}
