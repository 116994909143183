@use "sass:math";

.block-instructions {
    --radius: #{rem(4)};
    --color: var(--black);
    --font-size-title: #{rem(14)};
    --font-size: #{rem(6)};
    --font-size-button: #{rem(12)};
    --padding: #{rem(21)};
}

.block-instructions {
    padding: 0 var(--padding);

    .steps {
        > li {
            display: flex;
            gap: #{rem(24)};
            margin-bottom: #{rem(20)};
        }

        .image-holder {
            width: #{rem(70)};
            flex: 0 0 #{rem(70)};
        }

        figure {
            @include aspect-ratio(70,66);
            border: var(--stroke) solid var(--color);
            overflow: hidden;
            border-radius: var(--radius);
            
            img {
                border-radius: var(--radius);
                position: absolute;
                top:0;
                left:0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .content {
            flex: 1 0 50%;
            padding: #{rem(14)} #{rem(18)} 0 0;
            border-top: var(--stroke) solid var(--color);

            > .title {
                @include font-sans(.9);
                text-wrap: balance;
                font-size: var(--font-size-title);
                text-transform: uppercase;
            }

            > p {
                @include font-sans(1);
                font-size: var(--font-size);
                text-transform: uppercase;
                margin-top: .8em;
                letter-spacing: -.001em;
            }
        }
    }

    .button-holder {
        padding-bottom: #{rem(28)};
    }

    #Sidemenu & {
        .button-holder {
            display: none;
        }
    }

    .button {
        @include font-sans();
        text-transform: uppercase;
        font-size: var(--font-size-button);
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: #{rem(64)};
        border: var(--stroke) dashed currentColor;

        figure {
            position: absolute;
            top: #{rem(-68)};
            right: #{rem(-10)};
            width: #{rem(151.68px)};
            
            img {
                width: 100%;
                height: auto;
            }
        }
    }
}
