#Sidemenu {
    --max-width: #{rem(390)};
    --bg: var(--white);
    --color: var(--black);
    --padding: 0 #{rem(21)};
    
    @media (min-width: $smartphone) {
        --padding: var(--header-height);
        --max-width: #{rem(390)};
        --radius: #{rem(20)};
        --border-holder: 0;
    }

    @media (max-width: $smartphone) {
        --padding: calc(var(--full-header-height) - 1px);
        --max-width: 100%;
        --radius: #{rem(20)};
        --border-holder: var(--stroke) solid var(--black);
    }
}

#Sidemenu {
    @include z-index($z-index-sidemenu);

    position: fixed;
    width: 100vw;
    height: calc(var(--vh, 1vh) * 100);
    right: 0;
    top: 0;
    border-top-left-radius: var(--radius);
    border-top-right-radius: var(--radius);
    overflow: hidden;


    > .content {
        position: absolute;
        top:0;
        right: 0;
        width: 100%;
        max-width: var(--max-width);
        height: 100%;

        padding: 0 0 0;

        color: var(--color);

        @media (min-width: $smartphone) {
            overflow-y: scroll;
            overflow-x: hidden;
            -webkit-overflow-scrolling: touch;
            border-left: var(--stroke) solid var(--black);
            background-color: var(--bg);
        }

        > .__holder {
            padding: var(--padding) 0 0;
        }
    }

    @media (max-width: $smartphone) {
        .__holder::before {
            position: absolute;
            content: '';
            top: var(--padding);
            left: 0;
            width: 100%;
            height: 100%;
            background-color: var(--bg);
            border-top: var(--border-holder);
        }

        .__holder > div {
            position: relative;
        }
    }

    footer {
        position: relative;
        @include aspect-ratio(390,63);

        a {
            @include basic-a();
            position: absolute;
            display: block;
            top:0;
            left: 0;
            width: 100%;
            height: 100%;
           
        }

        svg {
            position: absolute;
            top:0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        @media (max-width: $smartphone) {
            &::before {
                position: absolute;
                content: '';
                top:0;
                left:0;
                width: 100%;
                height: 100vh;
                background-color: var(--black);
            }
        }
    }

    > .bg {
        position: absolute;
        border: none;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0);
    }

    &[aria-expanded='false'] {
        visibility: hidden;
        pointer-events: none;
    }

    .top {
        @include z-index(1);
        align-items: center;
        display: flex;
        height: var(--header-height);
        justify-content: flex-end;
        padding: var(--padding-xs);
        position: absolute;
        top: 0;
        right: 0;
    }

    .toggle-menu {
        @include font-sans(1.2);

        font-size: var(--font-size-toggle);
        color: inherit;
        background-color: transparent;
        padding: 0;
    }

   

    .nav-item {
        @include basic-a();
        @include font-sans();

        color: currentColor;
        display: block;
        overflow: hidden;

        &[aria-current="page"] {
            .__text { font-weight: bold; }
        }

        .__text {
            display: block;
            padding: var(--padding-xs) 0;
            position: relative;
        }
    }

    .sublink {
        padding: var(--padding-xs) 0;

        .label,
        li:not(:last-child) { margin-bottom: var(--padding-xs); }

        .label {
            @include font-sans(1);
            font-size: var(--font-size-label);
        }

        .nav-item .__text {
            padding: var(--padding-xs) 0;
        }
    }

    .list li {
        position: relative;
    }
}