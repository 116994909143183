#Preloader {
    --font-size: var(--font-size-s);
    --color: var(--white);
    --bg: var(--black);

    @media (min-width: $smartphone) {
        --logo-size: #{rem(200)};
    }

    @media (max-width: $smartphone) {
        --logo-size: #{rem(150)};
    }
}

#Preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    
    @include z-index($z-index-loader);
    color: var(--color);
    background-color: var(--bg);
    
    align-items: center;
    flex-direction: column;
    display: flex;
    justify-content: center;

    @include font-sans(1);
    font-size: var(--font-size);
    text-transform: uppercase;

    .logo {
        fill: white;
        width: var(--logo-size);
        height: auto;
        opacity: 0;
    }

    .progress {
        background-color: transparent;
        border: 1px solid var(--white);
        height: rem(8);
        border-radius: rem(4);
        margin-top: rem(14);
        position: relative;
        width: rem(80);
        opacity: 0;
    }
    
    .thumb {
        background-color: var(--white);
        height: 100%;
        left: 0;
        position: absolute;
        transform: scale3d(0, 1, 1);
        transform-origin: center left;
        width: 100%;
    }
}
