// #Footer {
//     --font-size: var(--font-size-small);
//     padding: var(--padding-s) var(--padding-xs) var(--padding-xl);
// }

// #Footer > nav {
//     display: flex;
//     align-items: flex-end;

//     a {
//         @include font-sans(1.2);
//         font-size: var(--font-size);
//         color: inherit;
//         margin: 0 1em;
//     }
// }

// @media (max-width: $smartphone) {
//   #Footer {
//       --font-size: rem(12px);
//   }
// }


@use "sass:math";

#Footer {
    --font-size: var(--font-size-small);
    --padding-v-bottom: var(--padding-xs);
    --padding-v-top: var(--padding-l);
    --padding-h: var(--padding-l);
    
    @media (max-width: $smartphone) {
        --font-size: var(--font-size-xxsmall);
        --padding-h: var(--padding-s);
        --padding-v-bottom: var(--padding-l);
    }
}

#Footer {
    @include font-sans();

    font-size: var(--font-size);
    padding-bottom: var(--scrollbar-height);
    position: relative;
    
    a {
        @include basic-a();
        color: var(--white);
    }

    .top {
        padding: var(--padding-v-top) var(--padding-h);
    }

    .bottom {
        padding: var(--padding-v-bottom) var(--padding-h);
    }

    @media (max-width: $smartphone) {
        .top {
            > div {
                display: flex;
                flex-direction: column;
                            
                &:not(:last-child) {
                    margin-bottom: var(--padding-s);
                }
            }
        }

        .bottom {
            div {
                display: flex;
                flex-direction: column;

                *:not(:last-child) {
                    margin-bottom: var(--padding-xxxs);
                }
            }
        }
    }
    
    @media (min-width: $smartphone) {
        .top {
            > div {
                display: flex;
    
                > div:not(.newsletter) {
                    display: flex;
                    flex-direction: column;
                                
                    &:not(:last-child) {
                        margin-right: var(--padding-xl);
                        margin-bottom: var(--padding-xxs);
                    }
                }
            }
        }

        .bottom {
            align-items: center;
            display: flex;
            justify-content: flex-start;

            a {
                margin-right: var(--padding-s);
            }
        }

        .newsletter {
            flex-basis: rem(550);
            margin-right: 0;
            margin-left: auto;
        }
    }
}
