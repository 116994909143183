@use "sass:math";

:root {
    --font-sans: 'HelveticaNeue-Medium', sans-serif;
    --font-sans-bold: 'HelveticaNeue-Bold', sans-serif;
    --font-sans-italic: 'HelveticaNeue-Medium', sans-serif;

    --font-size-xxlarge: #{math.div(42px, 16px) * 1rem};
    --font-size-xlarge: #{math.div(36px, 16px) * 1rem};
    --font-size-large: #{math.div(32px, 16px) * 1rem};
    --font-size-medium: #{math.div(28px, 16px) * 1rem};
    --font-size-base: #{math.div(24px, 16px) * 1rem};
    --font-size-small: #{math.div(20px, 16px) * 1rem};
    --font-size-xsmall: #{math.div(18px, 16px) * 1rem};
    --font-size-xxsmall: #{math.div(16px, 16px) * 1rem};
    --font-size-xxxsmall: #{math.div(14px, 16px) * 1rem};
    --font-size-xxxxsmall: #{math.div(12px, 16px) * 1rem};
    --font-size-xxxxxsmall: #{math.div(8px, 16px) * 1rem};

    --line-height-sans: .8;
    --letter-spacing: -0.05em;

    --zoom: 1;

    --min-font-size: 10px;
    --max-font-size: 48px;
    --font-size-ref: 1.1111vw;
    --font-size: clamp(var(--min-font-size), var(--font-size-ref) * var(--zoom), var(--max-font-size));
    font-size: var(--font-size);

    @media (min-aspect-ratio: 5/3) {
        --font-size-ref: .7vw;
    }

    @media (min-aspect-ratio: 4/2) {
        --font-size-ref: .6vw;
    }
    
    @media (max-width: $smartphone) {
        --font-size: 16px;
    }
}

@mixin font-sans($line-height:0) {
    font-family: var(--font-sans);
    font-weight: 500;
    letter-spacing: var(--letter-spacing);
    @if $line-height > 0 { line-height: $line-height  }
    @else { line-height: var(--line-height-sans);  }
}
@mixin font-sans-bold($line-height:0) {
    font-family: var(--font-sans-bold);
    font-weight: 700;
    letter-spacing: var(--letter-spacing);
    @if $line-height > 0 { line-height: $line-height  }
    @else { line-height: var(--line-height-sans);  }
}
@mixin font-sans-italic($line-height:0) {
    font-family: var(--font-sans);
    font-weight: 200;
    font-style: italic;
    @if $line-height > 0 { line-height: $line-height  }
    @else { line-height: var(--line-height-sans);  }
}
