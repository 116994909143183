@use "sass:math";

#Billboard {
    --width: 100%;
    --height: var(--billboard-height);
    --offset: var(--header-height);
    
    --color: var(--black);
    --bg: var(--white);
    --icon-fill: var(--grey);
    
                
    @media (min-width: $smartphone) {
        --font-size: #{rem(122)};
        --padding: 0 0 #{rem(34)} #{rem(24)};
        --icon-height: #{rem(30)};
        --icon-right: #{rem(24)};
        --icon-bottom: #{rem(37)};
        --border: #{rem(10)};
    }

    @media (max-width: $smartphone) {
        --font-size: #{rem(54)};
        --padding: 0 0 #{rem(28)} #{rem(18)};
        --icon-height: #{rem(12)};
        --icon-right: #{rem(16)};
        --icon-bottom: #{rem(29)};
        --border: #{rem(9)};
    }
    
}

#Billboard {
    @include z-index($z-index-billboard);
    position: fixed;
    width: var(--width);
    padding-top: var(--offset);
    background-color: var(--black);

    > .holder {
        position: relative;
        width: 100%;
        height: var(--height);
        padding: var(--padding);
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;

        color: var(--color);
        background-color: var(--bg);

        &::before {
            position: absolute;
            content: '';
            bottom: 0;
            left: 0;
            width: 100%;
            height: var(--border);
            background-color: var(--color);
        }
    }

    .title {
        @include font-sans(.8);
        font-size: var(--font-size);
        letter-spacing: -0.05em;
        margin: 0 0 0 -.065em;
    }

    .braile {
        @include basic-a();
        position: absolute;
        bottom: var(--icon-bottom);
        right: var(--icon-right);
        
        svg {
            width: auto;
            height: var(--icon-height);
            fill: var(--icon-fill);
        }
    }
}