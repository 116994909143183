@use "sass:math";

.block-cta {
    --color: var(--black);
    --font-size-cities: #{rem(17)};
    --font-size-title: #{rem(12)};
    --font-size: #{rem(10)};
    --padding: #{rem(21)};
}

.block-cta {
    display: flex;
    justify-content: center;
    padding: #{rem(24)} var(--padding) #{rem(24)};

    border-top: var(--stroke) dashed var(--color);

    a {
        @include basic-a();
        @include font-sans(1);
        display: inline-block;
        font-size: #{rem(9)};
        padding: #{rem(19)}  #{rem(19)};
        border: var(--stroke) solid var(--color);
        color: var(--color);
        text-transform: uppercase;
    }
}
