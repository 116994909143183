:root {
    --white: #ffffff;
    --black: #000;
    --grey: #C2C2C2;

    --primary: var(--black);
    --secondary: var(--white);

    --assertive: #ff00ff;
    --focus: #ff00ff;
    --color-error: #ff0000;
    --color-sucess: #4dc247;
}