#Instructions {
    @include z-index($z-index-instructions);
    position: fixed;

    top:0;
    left:0;
    width: 100%;
    height: 100%;
    opacity: 0;

    cursor: pointer;

    @media (min-width: $smartphone) {
        --max-width: #{rem(434)};
        --radius: #{rem(7)};

        background: rgba(0, 0, 0, 0.8);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        display: flex;
       
        align-items: center;
        justify-content: center;
    }

    @media (max-width: $smartphone) {
        --max-width: 100%;
        --radius: 0;

        background-color: transparent;
        padding-top: var(--full-header-height);
       
        /*overflow-y: scroll;
        overflow-x: hidden;
        -webkit-overflow-scrolling: touch;*/
    }
}

.instructions__window {
    position: relative;
    opacity: 0;

    @media (min-width: $smartphone) {
        --font-size: #{rem(40)};
        --icon-height: #{rem(29)};
        --padding: 0 #{rem(22)};
    }

    @media (max-width: $smartphone) {
        --padding: #{rem(22)} 0 0;
        min-height: calc(100vh - var(--full-header-height));

        &::before {
            position: absolute;
            content:'';
            top: 0;
            left: 0;
            width: 100%;
            height: 200%;
            background-color: var(--white);
        }

        > div {
            position: relative;
        }
    }
}

.instructions__window {
    width: 100%;
    max-width: var(--max-width);
    padding: var(--padding);
    border-radius: var(--radius);
    color: var(--black);
    background-color: var(--white);

    @media (max-width: $smartphone) {
        .header-mini {
            margin-top: 0;
        }
    }

    @media (min-width: $smartphone) {
        overflow: hidden;
    }

    header {
        @media (min-width: $smartphone) {
            position: relative;
            padding: #{rem(36)} #{rem(21)} #{rem(30)};

            &::before {
                position: absolute;
                content: '';
                left:-25%;
                bottom: 0;
                width: 150%;
                height: #{rem(9)};
                background-color: var(--black);
            }

            .title {
                @include font-sans(.8);
                font-size: var(--font-size);
                letter-spacing: -0.05em;
                margin: 0 0 -.05em -.065em;
            }

            .logo {
                @include basic-a();
                position: absolute;
                top: #{rem(40)};
                right: #{rem(26)};
                
                svg {
                    width: auto;
                    height: var(--icon-height);
                    fill: currentColor;
                }
            }
        }

        @media (max-width: $smartphone) {
            display: none;
        }
    }
}