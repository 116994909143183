@use "sass:math";

#Header {
    --width: 100%;
    --height: var(--header-height);
    --color: var(--black);
    --bg: var(--white);
        
    @media (min-width: $smartphone) {
        --font-size: #{rem(16)};
        --icon-height: #{rem(34)};
        --padding: 0 #{rem(24)};
        --logo-padding: 0 0 0 #{rem(24)};
        --stroke-offset: #{rem(9)};
        --radius: #{rem(20)};
    }

    @media (max-width: $smartphone) {
        --font-size: #{rem(10)};
        --icon-height: #{rem(21)};
        --padding: 0 #{rem(16)} 0 #{rem(18)};
        --logo-padding: 0 0 0 #{rem(20)};
        --stroke-offset: #{rem(9)};
        --radius: #{rem(20)};
    }
}

#Header {
    @include z-index($z-index-header);
    position: fixed;
    width: var(--width);
    height: var(--height);
    right: 0;
    top: 0;
    padding: var(--padding);

    border-top-left-radius: var(--radius);
    border-top-right-radius: var(--radius);

    display: flex;
    justify-content: space-between;
    align-items: center;

    color: var(--color);
    background-color: var(--bg);
    border-bottom: var(--stroke) solid var(--color);

    .title {
        flex: 0 1 auto;
        @include font-sans-bold();
        font-size: var(--font-size);
    }

    .logo {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 0 1 auto;
        height: var(--header-height);
        padding: var(--logo-padding);

        &:before {
            position: absolute;
            content: '';
            left: 0;
            bottom: 0;
            width: var(--stroke);
            height: calc(var(--height) - var(--stroke-offset));
            background-color: var(--color);
        }

        svg {
            width: auto;
            height: var(--icon-height);
            fill: var(--color);
        }
    }
}