html {
    width: 100%;
    height: 100%;
}

body {
    width: 100vw;
    min-height: 100%;
    color: var(--black);
    background: var(--black);

    > video {
        visibility: hidden;
        pointer-events: none;
    }
    
    main {
        width: 100%;
    }
    
    overflow: hidden;
}

.link-end {
    position: absolute;
    @include z-index(9999);
    @include basic-a();
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    background-color: #FF0000;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;

    svg {
        fill: black;
        max-width: #{rem(320)};
        height: auto;
    }
}

.icon {
    display: inline-block;

    svg {
        height: 100%;
        width: 100%;
    }
}

.line-parent {
    overflow: hidden;
}

*[aria-current="page"] {
    pointer-events: none;
    text-decoration: none;
}

button[disabled] {
    pointer-events: none;
    opacity: .3;
}

// Tweakpane
.tp-dfwv {
    @include z-index($z-index-tweakpane);
}
